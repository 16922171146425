import { CSSProperties, FC } from 'react';
import classnames from 'classnames';
import styles from './App.module.scss';
import react from './assets/svg/react.svg';
import angular from './assets/svg/angular.svg';
import vue from './assets/svg/vue.svg';
import javascript from './assets/svg/javascript.svg';

const getAppUrl = (path: string, port: number) => {
  return process.env.NODE_ENV === 'development' ? `http://${window.location.hostname}:${port}` : `/${path}/`;
};

const getBackgroundImageCss = (url: string): CSSProperties => ({ backgroundImage: `url(${url})` });

const App: FC<{}> = () => {
  return (
    <div className={styles.Main}>
      <div className={styles.AppLinkWrapper}>
        <a id="app-logo-react" className={styles.AppLink} href={getAppUrl('sample-react', 3001)}>
          <span className={styles.Text}>React</span>
          <div className={classnames(styles.Icon, styles.Cover)} style={getBackgroundImageCss(react)} />
        </a>
        <a id="app-logo-angular" className={styles.AppLink} href={getAppUrl('sample-angular', 3002)}>
          <span className={styles.Text}>Angular</span>
          <div className={styles.Icon} style={getBackgroundImageCss(angular)} />
        </a>
        <a id="app-logo-vue3" className={styles.AppLink} href={getAppUrl('sample-vue3', 3005)}>
          <span className={styles.Text}>Vue 3</span>
          <div className={styles.Icon} style={getBackgroundImageCss(vue)} />
        </a>
        <a id="app-logo-javascript" className={styles.AppLink} href={getAppUrl('sample-javascript', 3004)}>
          <span className={styles.Text}>Javascript</span>
          <div className={styles.Icon} style={getBackgroundImageCss(javascript)} />
        </a>
      </div>
      <div className={styles.Redirect}>
        <a className={styles.AppLink} href="https://code.roche.com/onedesign/web-components-kit-samples/-/tree/master">
          <h5 className={styles.Heading}>Check out the sample app project repo</h5>
        </a>
      </div>
    </div>
  );
};

export default App;
